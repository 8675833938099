<template>
  <div id="contact-list" v-cloak v-loading.fullscreen.lock="loading">
    <div class="container">
      <div class="search-box flex-a-center" :style="{ width: tableWidth }">
        <div class="flex-a-center">
          <span class="font" style="width: 50px">{{ $t("search") }}：</span>
          <!-- <zc-search open_path="{$webPath}" :address_list="address" title_type="user" title="contact_list"
                           :search_data="searchForm" callback="getData" :key="searchKey"
                           :must_search="mustSearch"></zc-search> -->
        </div>
        <el-input
          v-model="key"
          class="search-input ml10"
          :placeholder="$t('taskManagement.inputKeyword')"
          @keyup.enter.native="getList(0)"
        ></el-input>
        <el-button class="search-button-1" @click="getList(0)">{{
          $t("search")
        }}</el-button>
        <el-button class="search-button-2" @click="reset">{{
          $t("reset")
        }}</el-button>
        <div class="flex-a-center">
          <div class="recycle-btn ml20" @click="jumpRecycle">
            <i class="iconfont icon-huishouzhan"></i>
            <span>{{ $t("taskManagement.recycle") }}</span>
          </div>
        </div>
      </div>
      <h2 class="tab-tit flex-between mb10">
        <span>
          {{ $t("contactList.list") }}
        </span>
        <span class="flex-a-center">
          <div
            class="add-user_btn mr20"
            v-if="rightShow('CrmContact@contactInfoPut')"
            @click="viewAdd"
          >
            <i class="iconfont icon-xinzengkehu"></i>
            <span>{{ $t("contactList.new") }}</span>
          </div>
          <el-popover
            placement="right"
            width="700"
            ref="popover"
            trigger="click"
          >
            <div class="handel-popover">
              <div class="popover-left">
                <div class="popover-left-top">
                  {{ $t("contactList.allRecords") }}
                </div>
                <el-checkbox-group
                  v-model="checkListField"
                  @change="changeCheck"
                  class="popover-checkbox-group"
                >
                  <el-checkbox
                    v-for="(item, index) in allShowColumn"
                    :label="index"
                    :key="index"
                    >{{ item.label }}</el-checkbox
                  >
                </el-checkbox-group>
                <div class="popover-right-button"></div>
              </div>
              <div class="popover-right">
                <div class="popover-left-top">
                  {{ $t("contactList.currRecord") }}
                </div>
                <div class="popover-checkbox-group">
                  <div
                    v-for="(item, index) in allDisColumn"
                    class="has-item"
                    :key="index"
                  >
                    <span>{{ item.label }}</span>
                    <i
                      class="el-icon-delete red-text hand"
                      @click="delField(index)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="popover-handel-box flex-center">
              <zc-icon-btn
                :content="$t('cancel')"
                btn_type="success"
                btn_class="search_button popover-left-button cancel-btn"
                icon="icon-quxiao"
                @click="cancelField"
              ></zc-icon-btn>
              <zc-icon-btn
                :content="$t('confirm')"
                btn_type="warning"
                btn_class="search_button"
                icon="icon-queren"
                @click="confirmField"
              ></zc-icon-btn>
            </div>
            <div
              slot="reference"
              @click="setUp"
              class="yellow-text hand cus-tit"
            >
              <i class="iconfont icon-shezhi"></i>
              <span>{{ $t("contactList.customColumn") }}</span>
            </div>
          </el-popover>
          <!-- <zc-icon-btn content="<tags:lang>新增客户</tags:lang>" btn_type="primary" btn_class="search_button ml20" icon="icon-xinzengkehu" v-if="rightShow('CrmContact@contactInfoPut')" @click="viewAdd"></zc-icon-btn> -->
        </span>
      </h2>
      <div :style="{ width: tableWidth }">
        <el-table
          ref="skuTable"
          :data="tableData"
          row-key="id"
          tooltip-effect="dark"
          @selection-change="itemChange"
          class="table-con"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column width="100" :label="$t('handle')">
            <template slot-scope="scope">
              <el-popover placement="left" width="200" trigger="click">
                <div class="handel-inner">
                  <el-popover placement="right-end" width="158" trigger="hover">
                    <div
                      class="operation_inner"
                      v-if="selectOperation[0]['children'].length > 0"
                    >
                      <div
                        class="handel-btn"
                        @click="
                          application(3, {
                            id: scope.row.id,
                            index: 1,
                            type_id: item.value,
                          })
                        "
                        v-for="(item, index) in selectOperation[0]['children']"
                        :key="index"
                      >
                        {{ item.label }}
                      </div>
                    </div>
                    <div v-else class="centerText">
                      {{ $t("projectInfo.no") }}
                    </div>
                    <div class="handel-btn" slot="reference">
                      <span>{{ $t("contactList.changeGroup") }}</span>
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </el-popover>
                  <el-popover placement="right-end" width="158" trigger="hover">
                    <div
                      class="operation_inner"
                      v-if="selectOperation[1]['children'].length > 0"
                    >
                      <div
                        class="handel-btn"
                        @click="
                          application(3, {
                            id: scope.row.id,
                            index: 2,
                            type_id: item.value,
                          })
                        "
                        v-for="(item, index) in selectOperation[1]['children']"
                        :key="index"
                      >
                        {{ item.label }}
                      </div>
                    </div>
                    <div v-else class="centerText">
                      {{ $t("projectInfo.no") }}
                    </div>
                    <div class="handel-btn" slot="reference">
                      <span>{{ $t("contactList.addTag") }}</span>
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </el-popover>
                  <div
                    class="handel-btn"
                    @click="application(4, { id: scope.row.id })"
                    v-if="rightShow('CrmContact@contactInfo')"
                  >
                    {{ $t("contactList.addStar") }}
                  </div>
                  <div
                    class="handel-btn"
                    @click="view(scope.row.id, 1)"
                    v-if="rightShow('CrmContact@contactInfo')"
                  >
                    {{ $t("taskManagement.view") }}
                  </div>
                  <div
                    class="handel-btn"
                    @click="view(scope.row.id, 0)"
                    v-if="rightShow('CrmContact@contactInfoPut')"
                  >
                    {{ $t("edit") }}
                  </div>
                  <div
                    class="handel-btn"
                    @click="deleteUser([scope.row.id], 0)"
                    v-if="rightShow('CrmContact@deleteUser')"
                  >
                    {{ $t("delete") }}
                  </div>
                </div>
                <el-button slot="reference" size="mini">
                  <i class="iconfont icon-qita"></i>
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            class-name="can-move"
            align="left"
            :width="widthObj[item.prop]"
            show-overflow-tooltip
            v-for="(item, index) in col"
            :key="is_show == 0 ? `col_${index}` : `dro_${index}`"
            :prop="dropCol[index].prop"
            :label="item.label"
          >
            <template slot="header">
              <span v-if="item.prop == 'name'" class="company-name">
                {{ item.label }}
              </span>
              <template v-else>
                {{ item.label }}
              </template>
            </template>
            <template slot-scope="scope">
              <div
                v-if="dropCol[index].prop == 'tags_name'"
                class="select-tags-box"
              >
                <div
                  v-for="(it, index) in scope.row.tags_name"
                  :style="'background-color:' + it.value"
                  class="select-tags flex-center"
                  :key="index"
                >
                  {{ it.name }}
                </div>
              </div>
              <div v-else-if="dropCol[index].prop == 'consignee'">
                {{ scope.row.consignee }}
              </div>
              <div v-else-if="dropCol[index].prop == 'star' && scope.row.star">
                <el-rate v-model="scope.row.star" disabled text-color="#FF7315">
                </el-rate>
              </div>
              <div
                v-else-if="dropCol[index].prop == 'name'"
                class="flex-a-center"
              >
                <i
                  class="iconfont icon-yishoucang mr10 red-text hand"
                  v-if="scope.row.is_like"
                  @click="application(2, { index: 5, id: scope.row.id })"
                ></i>
                <i
                  class="iconfont icon-weishoucang mr10 hand"
                  v-else
                  @click="application(2, { index: 4, id: scope.row.id })"
                ></i>
                <el-tag @click="view(scope.row.id, 1)">{{
                  scope.row.name
                }}</el-tag>
              </div>
              <div v-else>
                <p>{{ scope.row[dropCol[index].prop] }}</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        class="flex handel-bottom"
        v-if="rightShow('CrmContact@application')"
      >
        <div class="handel-inner_box">
          <div>
            <span class="select-span mr24">
              {{ $t("taskManagement.selected") }}{{ selectNum }}/{{ pageSize }}
            </span>
            <el-cascader
              size="mini"
              clearable
              class="mr24"
              v-model="selectType"
              :options="selectOperation"
              @change="addStartShow"
            ></el-cascader>
            <el-button
              type="primary"
              @click="application(0)"
              v-if="rightShow('CrmContact@application')"
              size="mini"
            >
              {{ $t("taskManagement.apply") }}
            </el-button>
          </div>
          <el-pagination
            @size-change="getList(1, searchData)"
            @current-change="getList(1, searchData)"
            :current-page.sync="page"
            :page-sizes="[20, 40, 60, 80, 100]"
            :page-size.sync="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total.sync="total"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        :title="$t('contactList.addStar')"
        :visible.sync="starVisible"
        width="500px"
        v-move
        @close="cancelApplication"
      >
        <div class="dia-container">
          <div
            class="mb10 flex-a-center start-box"
            v-for="(it, index) in starMainGroup"
            :key="index"
          >
            <el-select
              v-model="it['name']"
              :placeholder="$t('taskManagement.select')"
              @change="changeStar"
              size="mini"
              class="mr10"
            >
              <el-option
                v-for="item in starGroup"
                :disabled="
                  !(
                    !(starArray.indexOf(item.value) >= 1) ||
                    it['name'] == item.value
                  )
                "
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-rate v-model="it['value']" allow-half> </el-rate>
            <i
              class="el-icon-delete red-text dia-icon"
              @click="delCompany(index)"
            ></i>
          </div>
          <div class="flex-a-center hand mt20" @click="addCompany()">
            <i class="el-icon-circle-plus-outline yellow-text mr5"></i>
            <span>{{ $t("contactList.addStarType") }}</span>
          </div>
        </div>
        <div slot="footer">
          <zc-icon-btn
            :content="$t('cancel')"
            btn_type="danger"
            btn_class="search_button"
            icon="icon-quxiao"
            v-if="rightShow('CrmContact@application')"
            @click="cancelApplication"
          ></zc-icon-btn>
          <zc-icon-btn
            :content="$t('confirm')"
            btn_type="primary"
            btn_class="search_button"
            icon="icon-queren"
            v-if="rightShow('CrmContact@application')"
            @click="application(1)"
          ></zc-icon-btn>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import { savePageOption } from "@/utils/common";
import { toMessage } from "@/utils/message";
import "@/plugins/move";
import ZcIconBtn from "@/components/ZcIconBtn";
export default {
  components: {
    ZcIconBtn,
  },
  name: "ContactList",
  data() {
    return {
      searchData: {},
      address: {},
      searchForm: [
        "公司名称",
        "公司邮箱",
        "客户简介",
        "客户来源",
        "登录名",
        "有无登录名",
        "公司地址",
        "客户分组",
        "客户区域",
        "客户标签",
        "创建者",
        "负责人",
        "联系人名称",
        "联系人电话",
        "联系人邮件",
        "联系人称谓",
        "联系人地址",
        "创建时间",
      ],
      mustSearch: ["公司名称", "客户区域", "负责人", "联系人名称"],
      is_show: 0,
      visible: false,
      starVisible: false,
      starArray: [],
      starMainGroup: [],
      starGroup: [],
      checkListField: [],
      allDisColumn: [],
      checkList: [],
      source_type: 1,
      addr: true,
      searchKey: 1,
      idsList: [],
      selectNum: 0,
      selectOperation: [
        {
          label: this.$t("contactList.changeGroup"),
          value: 1,
          children: [],
        },
        {
          label: this.$t("contactList.addTag"),
          value: 2,
          children: [],
        },
        {
          label: this.$t("contactList.addStar"),
          value: 3,
        },
        {
          label: this.$t("contactList.like"),
          value: 4,
        },
        {
          label: this.$t("contactList.dislike"),
          value: 5,
        },
        {
          label: this.$t("contactList.delete"),
          value: 6,
        },
      ],
      selectType: [],
      loading: true,
      page: 1,
      pageSize: 20,
      key: "",
      total: 0,
      sortProp: "",
      sortOrder: "",
      tableData: [],
      time: "",
      status: -1,
      is_first: 1,
      searchConfig: {
        date: {
          type: "date",
          startName: this.$t("contactList.startDate"),
          link: this.$t("taskManagement.to"),
          endName: this.$t("contactList.endDate"),
          name: this.$t("contactList.selectCreateDate"),
          default: "",
        },
        key: {
          type: "input",
          desc: this.$t("taskManagement.inputKeyword"),
          name: this.$t("search"),
          default: "",
        },
        group: {
          type: "select",
          desc: this.$t("contactList.userGroup"),
          option: [],
          default: "",
        },
      },
      allShowColumn: [
        {
          label: this.$t("contactList.companyName"),
          prop: "name",
        },
        {
          label: this.$t("contactList.contact"),
          prop: "consignee",
        },
        {
          label: this.$t("contactList.label"),
          prop: "tags_name",
        },
        {
          label: this.$t("contactList.accessionName"),
          prop: "account",
        },
        {
          label: this.$t("contactList.createDate"),
          prop: "create_time",
        },
        {
          label: this.$t("contactList.userGroup"),
          prop: "group_name",
        },
        {
          label: this.$t("contactList.star"),
          prop: "star",
        },
        {
          label: this.$t("contactList.lastContact"),
          prop: "update_time",
        },
        {
          label: this.$t("contactList.news"),
          prop: "content",
        },
      ],
      col: [
        {
          label: this.$t("contactList.companyName"),
          prop: "name",
        },
        {
          label: this.$t("contactList.contact"),
          prop: "consignee",
        },
        {
          label: this.$t("contactList.label"),
          prop: "tags_name",
        },
        {
          label: this.$t("contactList.accessionName"),
          prop: "account",
        },
        {
          label: this.$t("contactList.createDate"),
          prop: "create_time",
        },
        {
          label: this.$t("contactList.userGroup"),
          prop: "group_name",
        },
        {
          label: this.$t("contactList.star"),
          prop: "star",
        },
        {
          label: this.$t("contactList.lastContact"),
          prop: "update_time",
        },
        {
          label: this.$t("contactList.news"),
          prop: "content",
        },
      ],
      dropCol: [
        {
          label: this.$t("contactList.companyName"),
          prop: "name",
        },
        {
          label: this.$t("contactList.contact"),
          prop: "consignee",
        },
        {
          label: this.$t("contactList.label"),
          prop: "tags_name",
        },
        {
          label: this.$t("contactList.accessionName"),
          prop: "account",
        },
        {
          label: this.$t("contactList.createDate"),
          prop: "create_time",
        },
        {
          label: this.$t("contactList.userGroup"),
          prop: "group_name",
        },
        {
          label: this.$t("contactList.star"),
          prop: "star",
        },
        {
          label: this.$t("contactList.lastContact"),
          prop: "update_time",
        },
        {
          label: this.$t("contactList.news"),
          prop: "content",
        },
      ],
      widthObj: {
        name: "200",
        account: "150",
        create_time: "200",
        consignee: "200",
        tags_name: "250",
        group_name: "200",
        star: "200",
        update_time: "200",
        content: "200",
      },
      listCol: [],
    };
  },
  filters: {
    formateColumn: function (val) {
      let sourceObj = {
        name: 0,
        consignee: 1,
        tags_name: 2,
        create_time: 3,
        group_name: 4,
        star: 5,
        update_time: 6,
        content: 7,
      };
      return sourceObj[val];
    },
  },
  computed: {
    // 计算表格宽度
    tableWidth: function () {
      let wArr = [];
      this.col.forEach((item) => {
        wArr.push(this.widthObj[item.prop]);
      });
      // prev, cur, idx, arr
      let temW = wArr.reduce(function (prev, cur) {
        return prev * 1 + cur * 1;
      });
      // 多选框以及操作栏的宽度155
      if (temW + 155 > 1400) {
        return "100%";
      } else {
        return temW + 155 + "px";
      }
    },
  },
  inject: ["rightShow"],
  methods: {
    addTab: function (
      option = "Index",
      action = "welCome",
      name = "首页",
      param
    ) {
      let url = "/" + option + "/" + action;
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      console.log(name);
      this.$router.push(url);
    },
    //删除客户  0单条删  1批量删除
    deleteUser(ids, operation) {
      this.$confirm(this.$t("management.confirmDelete"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.axios
          .post("/home/CrmContact/deleteUser", {
            ids: ids,
            operation: operation,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            this.addTab(
              "CrmContact",
              "contactList",
              "<tags:lang>客户列表</tags:lang>"
            );
          })
          .catch(() => {});
      });
    },
    //取消
    cancelApplication() {
      this.selectType = [];
      this.starVisible = false;
      this.source_type = 1;
    },
    //增加自定义字段
    addCompany() {
      this.starMainGroup.push({ name: "", value: "" });
    },
    //改变星级
    changeStar() {
      this.starArray = [];
      this.starMainGroup.forEach((item) => {
        this.starArray.push(item.name);
      });
    },
    //批量添加星级
    addStartShow() {
      this.source_type = 1;
      if (this.selectType[0] == 3) {
        if (this.checkList.length == 0) {
          this.$message(toMessage("error", this.$t("contactList.error")));
          this.selectType = [];
          return;
        }
        this.starMainGroup = [];
        this.starMainGroup.push({ name: "", value: "" });
        this.starVisible = true;
        this.starArray = [];
      }
    },
    //删除自定义地段
    delCompany(index) {
      this.starMainGroup.splice(index, 1);
      this.starArray = [];
      this.starMainGroup.forEach((item) => {
        this.starArray.push(item.name);
      });
    },
    //获取callback的内容
    getData(data) {
      let ajax = {
        ...data.searchData,
      };
      this.searchData = ajax;
      if (data.type === 1) {
        this.getList(2, this.searchData);
      }
    },
    setUp() {
      this.allDisColumn = [];
      this.checkListField = [];
      var hash = {};
      this.allShowColumn.forEach((item, index) => {
        let obj = {};
        obj["label"] = item.label;
        obj["value"] = index;
        hash[item.prop] = obj;
      });
      this.col.forEach((item) => {
        this.checkListField.push(hash[item.prop]["value"]);
        this.allDisColumn.push(hash[item.prop]);
      });
    },
    //取消编辑字段
    cancelField() {
      this.checkListField = [];
      this.allDisColumn = [];
      document.getElementById("contact-list").click();
    },
    //完成编辑字段
    confirmField() {
      if (this.checkListField.length == 0) {
        this.$message(toMessage("error", this.$t("contactList.error1")));
        return;
      }
      this.col = [];
      this.dropCol = [];
      let listCol = [];
      this.checkListField.sort();
      this.checkListField.forEach((item) => {
        listCol.push(
          JSON.parse(JSON.stringify(this.allShowColumn[item]["prop"]))
        );
        this.col.push(JSON.parse(JSON.stringify(this.allShowColumn[item])));
        this.dropCol.push(JSON.parse(JSON.stringify(this.allShowColumn[item])));
      });
      this.listCol = listCol;
      this.checkListField = [];
      this.allDisColumn = [];
      if (this.is_show == 1) {
        this.is_show = 0;
      } else {
        this.is_show = 1;
      }
      this.visible = false;
      let obj = {};
      obj["name"] = "CrmContact_contactList";
      obj["option"] = listCol;
      savePageOption(obj, "/");
      document.getElementById("contact-list").click();
    },
    //改变选项
    changeCheck(val) {
      this.allDisColumn = [];
      val.forEach((item) => {
        let obj = {};
        obj["label"] = this.allShowColumn[item].label;
        obj["value"] = item;
        this.allDisColumn.push(obj);
      });
    },
    //删除字段
    delField(index) {
      this.checkListField.splice(index, 1);
      this.allDisColumn.splice(index, 1);
    },
    //列拖拽
    columnDrop() {
      const wrapperTr = document.querySelector(".el-table__header-wrapper tr");
      Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        handle: ".can-move",
        onEnd: (evt) => {
          const oldItem = this.dropCol[evt.oldIndex - 2];
          this.dropCol.splice(evt.oldIndex - 2, 1);
          this.dropCol.splice(
            evt.newIndex - 2,
            0,
            JSON.parse(JSON.stringify(oldItem))
          );
          let listCol = [];
          this.dropCol.forEach((item) => {
            listCol.push(item.prop);
          });
          let obj = {};
          obj["name"] = "CrmContact_contactList";
          obj["option"] = listCol;
          savePageOption(obj, "/");
        },
        // 多选框禁止拖动
        onMove: (evt) => {
          if (evt.related.className.indexOf("el-table-column--selection") > 0) {
            return false;
          }
        },
      });
    },
    //重置选择
    toggleSelection(rows) {
      this.tableData.forEach((row) => {
        if (rows.indexOf(row.id) >= 0) {
          this.$nextTick(function () {
            this.$refs.skuTable.toggleRowSelection(row, true);
          });
        }
      });
    },
    //选择框改变
    itemChange(val) {
      this.checkList = [];
      val.forEach((item) => {
        this.checkList.push(item.id);
      });
      this.selectNum = this.checkList.length;
      this.idsList = JSON.parse(JSON.stringify(this.checkList));
    },
    //应用
    application(opertion, option = {}) {
      let ajax = {};
      if (opertion == 0) {
        ajax["source_type"] = 1;
        ajax["ids"] = this.checkList;
        if (this.selectType.length < 1) {
          this.$message(toMessage("error", this.$t("contactList.error2")));
          return;
        }
        if (this.checkList.length == 0) {
          this.$message(toMessage("error", this.$t("contactList.error3")));
          return;
        }
        if (this.selectType[0] == 3) {
          this.$message(toMessage("error", this.$t("contactList.error4")));
          return;
        }
        if (this.selectType[0] == 6) {
          this.deleteUser(this.checkList, 1);
          return;
        }
        ajax["operation"] = this.selectType[0];
        if (Object.prototype.hasOwnProperty.call(this.selectType, 1)) {
          ajax["type_id"] = this.selectType[1];
        }
      } else if (opertion == 1) {
        //批量操作  添加星级
        ajax["source_type"] = this.source_type;
        ajax["ids"] = this.idsList;
        if (ajax["ids"].length == 0) {
          this.$message(toMessage("error", this.$t("contactList.error3")));
          return;
        }
        ajax["operation"] = 3;
        this.starMainGroup.forEach((item) => {
          this.starArray.push(item.name);
        });
        let KeyStar = {};
        this.starGroup.forEach((item) => {
          KeyStar[item.value] = item.label;
        });
        let bool = false;
        let text = "";
        ajax["option"] = this.starMainGroup.filter((item) => {
          if (item.name != "" && item.name != undefined) {
            if (item.value <= 0) {
              bool = true;
              if (Object.prototype.hasOwnProperty.call(KeyStar, item.name)) {
                text = KeyStar[item.name] + this.$t("contactList.error5");
              } else {
                text = item.name + this.$t("contactList.error5");
              }
            }
            return true;
          } else {
            return false;
          }
        });
        if (ajax["option"].length == 0) {
          bool = true;
          text = this.$t("contactList.error5");
        }
        if (bool) {
          this.$message(toMessage("error", text));
          return;
        }
      } else if (opertion == 2) {
        //喜欢 不喜欢
        ajax["source_type"] = 0;
        ajax["ids"] = [option.id];
        ajax["operation"] = option.index;
        this.axios
          .post("/home/CrmContact/application", ajax)
          .then((res) => {
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            if (ajax["operation"] == 4) {
              //喜欢
              this.tableData.forEach((item, index) => {
                if (ajax["ids"].indexOf(item["id"]) >= 0) {
                  this.tableData[index]["is_like"] = 1;
                }
              });
              return;
            } else if (ajax["operation"] == 5) {
              //不喜欢
              this.tableData.forEach((item, index) => {
                if (ajax["ids"].indexOf(item["id"]) >= 0) {
                  this.tableData[index]["is_like"] = 0;
                }
              });
              return;
            } else {
              this.getList(0);
            }
            this.starVisible = false;
            this.checkList = [];
            this.selectType = [];
          })
          .catch(() => {});
        return;
      } else if (opertion == 3) {
        //单行操作 修改分组 添加标签
        ajax["source_type"] = 0;
        ajax["ids"] = [option.id];
        ajax["operation"] = option.index;
        ajax["type_id"] = option.type_id;
      } else if (opertion == 4) {
        //单号操作 添加星级
        this.idsList = [option.id];
        this.source_type = 0;
        this.starMainGroup = [];
        this.starMainGroup.push({ name: "", value: "" });
        this.starVisible = true;
        this.starArray = [];
        return;
      }
      this.$confirm(this.$t("taskManagement.confirmToApply"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.axios
          .post("/home/CrmContact/application", ajax)
          .then((res) => {
            this.loading = false;
            if (res.data.status == 0) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            if (res.data.status == 1001) {
              //所选客户已含有此标签，重复的标签不会被添加
              this.$message(
                toMessage("warning", this.$t("contactList.warning"))
              );
            }
            if (res.data.status == 1002) {
              //所选客户已含有此分组，重复的标签不会被添加
              this.$message(
                toMessage("warning", this.$t("contactList.warning1"))
              );
            }
            if (res.data.status == 1003) {
              //所选客户已含有此分组，重复的标签不会被添加
              this.$message(
                toMessage("warning", this.$t("contactList.warning2"))
              );
            }
            this.starVisible = false;
            if (opertion == 0 && this.selectType[0] == 2) {
              this.getList(2);
              return;
            }
            this.getList(0);
            this.checkList = [];
            this.idsList = [];
          })
          .catch(() => {});
      });
    },
    //1查看  0编辑  2删除
    view(id, is_confirm) {
      let text = "";
      if (is_confirm == 1) {
        text = this.$t("contactList.info");
      }
      if (is_confirm == 0) {
        text = this.$t("contactList.edit");
      }
      if (is_confirm == 2) {
        text = this.$t("contactList.delete1");
      }
      if (is_confirm == 1) {
        this.addTab("CrmContact", "contactEdit", text, {
          id: id,
          is_view: is_confirm,
        });
      } else {
        this.addTab("CrmContact", "contactInfo", text, {
          id: id,
          is_view: is_confirm,
        });
      }
    },
    // 新增 0
    viewAdd() {
      this.addTab(
        "CrmContact",
        "contactInfo",
        "<tags:lang>客户</tags:lang>-<tags:lang>add</tags:lang>"
      );
    },
    changeTableSort(column) {
      this.sortProp = column.prop;
      this.sortOrder = column.order
        ? column.order == "ascending"
          ? "asc"
          : "desc"
        : "";
      this.getList(1, this.searchData);
    },
    reset() {
      this.key = "";
      this.time = "";
      this.status = "";
      this.page = 1;
      this.pageSize = 20;
      this.getList(0);
    },
    changeStatus() {
      this.getList(1, this.searchData);
    },
    getList(operation, ajaxData = {}) {
      let ajax = {};
      this.page = operation === 2 ? 1 : this.page;
      if (operation == 0) {
        ajax = {
          page: this.page,
          pageSize: this.pageSize,
          key: this.key.trim(),
          sortProp: this.sortProp,
          sortOrder: this.sortOrder,
          is_first: this.is_first,
        };
      } else {
        ajax = {
          page: this.page,
          pageSize: this.pageSize,
          sortProp: this.sortProp,
          sortOrder: this.sortOrder,
          is_first: this.is_first,
          ...ajaxData,
        };
      }
      this.loading = true;
      this.axios
        .post("/home/CrmContact/getContactList", ajax)
        .then((res) => {
          this.loading = false;
          if (res.data.status !== 1) {
            this.$message(toMessage("error", res.data.msg));
            return;
          }
          if (this.is_first) {
            this.searchConfig.group.option = res.data.data.group;
            if (res.data.data.habit_page) {
              let listCol = {};
              this.allShowColumn.forEach((item) => {
                listCol[item.prop] = item;
              });
              this.col = [];
              this.dropCol = [];
              res.data.data.habit_page.forEach((item) => {
                this.col.push(listCol[item]);
                this.dropCol.push(listCol[item]);
                //this.col = JSON.parse(JSON.stringify(res.data.habit_page))
                //this.dropCol = JSON.parse(JSON.stringify(res.data.habit_page))
              });
            }
            this.selectOperation[0].children = res.data.data.group;
            this.selectOperation[1].children = res.data.data.tags;
            this.starGroup = res.data.data.star;
            if (this.is_show == 1) {
              this.is_show = 0;
            } else {
              this.is_show = 1;
            }
            this.is_first = 0;
          }
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
          //this.toggleSelection([141,143])
          if (operation == 2) {
            this.toggleSelection(this.checkList);
          }
        })
        .catch(() => {});
    },
    jumpRecycle() {
      this.addTab(
        "CrmContact",
        "contactRecycle",
        "<tags:lang>回收站</tags:lang>"
      );
    },
  },
  created: function () {
    this.getList(0);
  },
  mounted() {
    this.columnDrop();
    const self = this;
    this.timer = setInterval(() => {
      try {
        if (self.addr && this.cacheAddr) {
          self.address = this.cacheAddr;
          self.addr = false;
          self.searchKey++;
        }
        if (!self.addr && this.cacheAddr) {
          clearInterval(this.timer);
        }
      } catch (e) {
        console.log("");
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  beforeCreate() {
    this.axios.get("/public/addr.js").then((res) => {
      this.cacheAddr = JSON.parse(res.data.slice(16, -1));
    });
  },
};
</script>

<style scoped>
#contact-list {
  padding: 28px !important;
  overflow: auto;
  height: 100%;
}
.status-box {
  margin-top: 20px;
  margin-bottom: 20px;
}

.select-tags-box {
  display: flex;
  flex-wrap: wrap;
}

.select-tags {
  height: 30px;
  float: left;
  padding: 4px 14px;
  color: #fff;
  margin-right: 5px;
  font-size: 14px;
  position: relative;
  border-radius: 3px;
  margin-bottom: 3px;
  max-width: 180px;
}

.ml56 {
  margin-left: 56px;
}

.handel-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 3;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  height: 84px;
  margin-left: -28px;
}

.handel-inner_box {
  min-width: 1280px;
  max-width: 1700px;
  display: flex;
  align-items: center;
  background: #fff;
  padding-left: 56px;
}

.el-pagination {
  margin: 0 auto;
}

.select-span {
  font-weight: 600;
  color: #333333;
  font-size: 14px;
}

.mr24 {
  margin-right: 24px;
}

.mb24 {
  margin-bottom: 24px;
}

.recycle-btn {
  padding: 5px 16px;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgba(255, 87, 92, 1);
  color: #ff575c;
  cursor: pointer;
}

.handel-inner {
  display: flex;
  flex-direction: column;
  padding: 2px;
}

.handel-btn {
  color: #333333;
  padding: 6px 10px;
  cursor: pointer;
}

.handel-btn:hover {
  color: #ff7315;
}

.el-rate__icon {
  font-size: 24px;
}

/* 表格手型 */
.el-table th:not(:first-child) {
  cursor: move;
}

.el-table th:last-child {
  cursor: pointer;
}

/* 筛选popover */
.handel-popover {
  display: flex;
  padding: 30px;
}

.popover-left {
  width: 258px;
  margin-right: 100px;
}

.popover-left-top {
  font-weight: 600;
  font-size: 16px;
}

.popover-checkbox-group {
  margin-top: 7px;
}

.popover-checkbox-group {
  border: 1px solid rgba(28, 28, 30, 0.1);
  border-radius: 3px;
  padding: 16px;
}

.popover-checkbox-group >>> .el-checkbox {
  width: 100%;
  margin-right: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 20px;
}

.popover-checkbox-group >>> .el-checkbox:last-child {
  margin-bottom: 0;
}

.popover-checkbox-group >>> .el-checkbox__label {
  padding-left: 0;
}

.popover-checkbox-group >>> .el-checkbox .el-checkbox__label {
  color: #1c1c1e;
  font-size: 16px;
}

.popover-checkbox-group
  >>> .el-checkbox__input.is-checked
  + .el-checkbox__label {
  color: #1c1c1e;
  font-size: 16px;
}

.popover-checkbox-group >>> .el-checkbox__inner {
  width: 15px;
  height: 15px;
}

.popover-checkbox-group >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ff7315;
  border-color: #ff7315;
}

.popover-right {
  width: 258px;
}

.has-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 16px;
  color: #1c1c1e;
  align-items: center;
  height: 19px;
}

.has-item:last-child {
  margin-bottom: 0px;
}

.popover-handel-box {
  margin-top: 5px;
  margin-bottom: 20px;
}

.popover-handel-box >>> .el-button {
  margin-right: 75px;
}

.operation_item {
  width: 100%;
  line-height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  min-width: 1280px;
  max-width: 1955px;
  padding-bottom: 110px;
}

.dia-icon {
  font-size: 21px;
  margin-left: 20px;
}

.el-rate {
  height: auto;
}

.el-dialog__header {
  font-weight: bold;
}

.dia-container {
  padding: 0 20px;
}

.company-name {
  padding-left: 30px;
}

.cus-tit {
  font-weight: normal;
  font-size: 14px;
}

.add-user_btn {
  font-weight: normal;
  font-size: 14px;
  color: #ff7315;
  cursor: pointer;
}
</style>